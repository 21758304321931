import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/noahgilmore.com/noahgilmore.com/src/components/BlogPageLayout.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I'm a Docker noob, and for whatever reason, I can never remember the best way to do things with Docker, whether it's checking which containers are running or starting/stopping them. Putting some common commands here mostly for personal reference.`}</p>
    <p>{`List containers:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": ""
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`docker ps -a`}</span></code></pre>
    <p>{`Check container status:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": ""
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`docker inspect ID | jq .[0].State`}</span></code></pre>
    <p>{`Start container:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": ""
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`docker container start ID`}</span></code></pre>

    <style {...{
      "className": "vscode-highlight-styles"
    }}>{`
  
  .dark-default-dark {
background-color: #1E1E1E;
color: #D4D4D4;
}
`}</style>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      